import React from 'react';
import logo from './assets/logo.png'; // Import your logo

function App() {
  return (
    <div className="font-sans bg-gray-100">
      {/* Navbar */}
      <header className="bg-blue-600 text-white py-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="ai-refinery logo" className="h-15 w-20 mr-3" />
            <h1 className="text-2xl font-bold">ai-refinery</h1>
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li><a href="#about" className="hover:underline">About</a></li>
              <li><a href="#products" className="hover:underline">Products</a></li>
              <li><a href="#contact" className="hover:underline">Contact</a></li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="bg-blue-100 py-20 text-center">
        <div className="container mx-auto">
          <h2 className="text-4xl font-bold mb-4">Welcome to ai-refinery</h2>
          <p className="text-lg text-gray-700 mb-6">
            Empowering AI innovation with refined solutions.
          </p>
          <a href="#products" className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-500">
            Explore Products
          </a>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-20 bg-white">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-6">About Us</h3>
          <p className="text-gray-700 text-lg">
            At ai-refinery, we provide cutting-edge AI products and tools to help businesses
            refine their AI models, data, and applications with ease.
          </p>
        </div>
      </section>

      {/* Products Section */}
      <section id="products" className="py-20 bg-gray-50">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-6">Our Products</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white shadow-md rounded p-6">
              <h4 className="text-xl font-bold mb-2">Data Crawler</h4>
              <p className="text-gray-600">
                An AI-powered web crawler to gather and organize data effortlessly.
              </p>
            </div>
            <div className="bg-white shadow-md rounded p-6">
              <h4 className="text-xl font-bold mb-2">AI Fine Tuner</h4>
              <p className="text-gray-600">
                Fine-tune your AI models with ease and precision using our intuitive platform.
              </p>
            </div>
            <div className="bg-white shadow-md rounded p-6">
              <h4 className="text-xl font-bold mb-2">RAG Based Apps</h4>
              <p className="text-gray-600">
                Explore our Retrieval-Augmented Generation (RAG) applications tailored for your needs.
              </p>
            </div>
            <div className="bg-white shadow-md rounded p-6">
              <h4 className="text-xl font-bold mb-2">AI Agents</h4>
              <p className="text-gray-600">
                Deploy AI agents designed for various purposes and tasks.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-white">
        <div className="container mx-auto text-center">
          <h3 className="text-3xl font-bold mb-6">Contact Us</h3>
          <p className="text-gray-700 mb-6">We’d love to hear from you!</p>
          <form className="max-w-md mx-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className="border w-full p-2 mb-4 rounded"
            />
            <textarea
              placeholder="Your message"
              className="border w-full p-2 mb-4 rounded h-32"
            ></textarea>
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-500"
            >
              Send
            </button>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto text-center">
          <img src={logo} alt="ai-refinery logo" className="h-8 w-8 mx-auto mb-2" />
          <p className="text-sm">&copy; 2024 ai-refinery. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
